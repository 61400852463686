<template>
    <div>
        <div class="container-fluid spritz spritz-top">
            <div class="row position-relative">
                <div class="col-12 col-lg-4 d-flex justify-content-around align-items-center flex-column order-lg-1 order-2 spritz__hero">
                    <div class="py-3 spritz__title py-md-5 px-lg-5 d-flex flex-column align-items-start">
                        <h1>Indulhat a<br>teraszozás!</h1>
                        <span class=" mb-lg-0 mb-2">Irány az erkély, vagy üljetek ki a kedvenc helyetek teraszára, és próbáljátok ki az új <span style="display:inline-block;">Gösser Spritz</span> sörkoktélokat!</span>
                        <a v-scroll-to="'#first'" class="btn-spritz mt-4">Felfedezem<img src="@/assets/images/icons/green-down-arrow.svg" alt="gösser" class="ml-2"></a>
                    </div>
                </div>
                <div class="col-12 col-lg-8 p-0 order-1 order-lg-2">
                    <img src="@/assets/images/bgr/spritz.jpg" class="img-fluid d-none">
                    <img src="@/assets/images/bgr/spritz-people.jpg" class="img-fluid article-hero-img">
                    <img src="@/assets/images/icons/fogyfel.svg" class="img-fluid fogyfel">
                </div>
                <img src="@/assets/images/icons/spritz-scroll.svg" alt="gösser" class="spritz__scroll">
            </div>
            <!-- STRAWBERRY  -->
            <div class="row " id="first">
                <div class="col-12 col-lg-6 premium--left-side spritz-pink-bg justify-content-center order-lg-1 order-2">
                    <div class="premium--left-side--inside mr-0 ml-0">
                        <div class="premium--left-side--inside__content leftBlock-premium-5" ref="premium-5">
                            <h2 id="premium" class="text-green">Gösser Spritz “Strawberry” 4%</h2>
                            <p class="text-green" style="font-weight:700;">
                                Az ellenállhatatlanul epres “Strawberry” változat minden kortyában ott a nyári koktélozás hangulata! 
                                <br> <br>
                                Ha nyár, akkor friss gyümölcsök! Pont annyira édes, amennyire jólesik a pihenés egy hosszú nap végén.
                            </p>

                            <div class="wrapper">
                                <table>
                                <thead>
                                    <tr  style="background-color: rgba(255,255,255,.2);">
                                    <th colspan="3"><span class="text-green" >Gösser Spritz “Strawberry” 4%</span></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr class="bg-tr-light-gold">
                                    <td></td>
                                    <td>Energia- és tápérték <br> 100 ml termékben</td>
                                    <td>Irányadó Napi Beviteli Érték <br>
                                        %*</td>
                                    </tr>
                                    <tr>
                                    <td class="first-cell-bg">Energia</td>
                                    <td>203,1 kJ/48,7 kcal</td>
                                    <td>2,4 %</td>
                                    </tr>
                                    <tr>
                                    <td class="first-cell-bg">Zsír</td>
                                    <td>0,0 g</td>
                                    <td>0,0 %</td>
                                    </tr>
                                    <tr>
                                    <td class="first-cell-bg">Ebből telített</td>
                                    <td>0,0 g</td>
                                    <td>0,0 %</td>
                                    </tr>
                                    <tr>
                                    <td class="first-cell-bg">Szénhidrát</td>
                                    <td>5,9 g</td>
                                    <td>2,2 %</td>
                                    </tr>
                                    <tr>
                                    <td class="first-cell-bg">Ebből cukor</td>
                                    <td>4,0 g</td>
                                    <td>4,4 %</td>
                                    </tr>
                                        <tr>
                                    <td class="first-cell-bg">Fehérje</td>
                                    <td>0,3 g</td>
                                    <td>0,6 %</td>
                                    </tr>
                                    <tr>
                                    <td class="first-cell-bg">Só</td>
                                    <td>0,0 g</td>
                                    <td>0,0 %</td>
                                    </tr>

                                    <tr>
                                    <th colspan="3" class="bottom-table-txt">* Referencia beviteli érték egy átlagos felnőtt számára (8400 kJ / 2000 kcal)</th>
                                    </tr>
                                    <tr>
                                    <th colspan="3" class="bottom-table-txt">Öszetevők: víz, árpamaláta, kukoricadara, fruktóz, komlókivonat, limelé sűrítményből, természetes aroma, eperlé sűrítményből, antioxidáns: citromsav, színezék: feketerépalé sűrítményből</th>
                                    </tr>
                                </tbody>
                                </table>  
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 p-lg-0 py-4 d-flex flex-column justify-content-center align-items-center spritz-green-bg order-1 order-lg-2">
                    <div class="d-flex flex-column align-items-center justify-content-center">
                        <img class="img-fluid" width="200" src="@/assets/images/products/spritz.png" alt="Gösser Spritz">
                        <img class="img-fluid strawberry-img my-4" src="@/assets/images/products/spritz-strawberry.png" alt="Gösser Spritz">
                        <img class="img-fluid strawberry-icons" src="@/assets/images/products/spritz-icons.png" alt="Gösser Spritz">
                    </div>
                    <div class="strawberry-bg"></div>
                </div>
                
            </div>
            <!-- BOTANIC -->
            <div class="row">
                <div class="col-12 col-lg-6 p-lg-0 py-4 d-flex flex-column justify-content-center align-items-center spritz-green-bg ">
                    <div class="d-flex flex-column justify-content-center align-items-center">
                        <img class="img-fluid" width="200" src="@/assets/images/products/spritz.png" alt="Gösser Spritz">
                        <img class="img-fluid my-4 botanic-img" src="@/assets/images/products/spritz-botanic.png" alt="Gösser Spritz">
                        <img class="img-fluid botanic-icons" src="@/assets/images/products/spritz-botanic-icons.png" alt="Gösser Spritz">
                    </div>
                    <div class="botanic-bg"></div>
                </div>
                <div class="col-12 col-lg-6 premium--left-side spritz-yellow-bg justify-content-center ">
                    <div class="premium--left-side--inside mr-0 ml-0">
                        <div class="premium--left-side--inside__content leftBlock-premium-5" ref="premium-5">
                            <h2 id="premium" class="text-green">Gösser Spritz "Botanic" 4%</h2>
                            <p class="text-green" style="font-weight:700;">
                                A fűszeres és fanyar “Botanic” verzió pedig a gin-tonic rajongók kedvence lesz.
                            </p>

                            <div class="wrapper">
                                <table>
                                <thead>
                                    <tr  style="background-color: rgba(255,255,255,.2);">
                                    <th colspan="3"><span class="text-green">Gösser Spritz "Botanic" 4%</span></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr class="bg-tr-light-gold">
                                    <td></td>
                                    <td>Energia- és tápérték <br> 100 ml termékben</td>
                                    <td>Irányadó Napi Beviteli Érték <br>
                                        %*</td>
                                    </tr>
                                    <tr>
                                    <td class="first-cell-bg">Energia</td>
                                    <td>177 kJ/42 kcal</td>
                                    <td>2,1 %</td>
                                    </tr>
                                    <tr>
                                    <td class="first-cell-bg">Zsír</td>
                                    <td>0,0 g</td>
                                    <td>0,0 %</td>
                                    </tr>
                                    <tr>
                                    <td class="first-cell-bg">Ebből telített</td>
                                    <td>0,0 g</td>
                                    <td>0,0 %</td>
                                    </tr>
                                    <tr>
                                    <td class="first-cell-bg">Szénhidrát</td>
                                    <td>4,8 g</td>
                                    <td>1,8 %</td>
                                    </tr>
                                    <tr>
                                    <td class="first-cell-bg">Ebből cukor</td>
                                    <td>2,9 g</td>
                                    <td>3,2 %</td>
                                    </tr>
                                        <tr>
                                    <td class="first-cell-bg">Fehérje</td>
                                    <td>0,3 g</td>
                                    <td>0,6 %</td>
                                    </tr>
                                    <tr>
                                    <td class="first-cell-bg">Só</td>
                                    <td>0,0 g</td>
                                    <td>0,0 %</td>
                                    </tr>

                                    <tr>
                                    <th colspan="3" class="bottom-table-txt">* Referencia beviteli érték egy átlagos felnőtt számára (8400 kJ / 2000 kcal)</th>
                                    </tr>
                                    <tr>
                                    <th colspan="3" class="bottom-table-txt">Öszetevők: víz, árpamaláta, kukoricadara, fruktóz, komlókivonat, citromlé sűrítményből, komlókivonat, szentjánoskenyérmagliszt, antioxidáns: aszkorbinsav, természetes aroma</th>
                                    </tr>
                                </tbody>
                                </table>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- SERVING -->
            <div class="row">
                <div class="col-12 col-lg-6 p-0">
                    <img class="img-fluid" src="@/assets/images/products/spritz-vegyes.jpg" alt="Gösser Spritz" style="object-fit:cover;width:100%;height:100%;">
                </div>
                <div class="col-12 col-lg-6 spritz-green-bg serving d-flex justify-content-xl-center flex-column">
                    <div class="serving-padding">
                        <h2 class="text-white">Szervírozási javaslat</h2>
                        <ul style="list-style-type: none;" class="p-0 mt-xxl-5 mt-4">
                            <li class="d-flex flex-row justify-content-start align-items-center">
                                <img class="img-fluid" src="@/assets/images/icons/spritz-serving1.svg" alt="Gösser Spritz">
                                <p class="text-white m-0 ml-4">Kérd csapolva az epres változatot, vagy hívd át a barátokat és teraszozzatok az erkélyen!</p> 
                            </li>
                            <li class="d-flex flex-row justify-content-start align-items-center my-4">
                                <img class="img-fluid" src="@/assets/images/icons/spritz-serving2.svg" alt="Gösser Spritz">
                                <p class="text-white m-0 ml-4">Keress egy talpas poharat, mehet a jég és már töltheted is kedvenc Gösser Spritz sörkoktélodat!</p> 
                            </li>
                            <li class="d-flex flex-row justify-content-start align-items-center">
                                <img class="img-fluid" src="@/assets/images/icons/spritz-serving3.svg" alt="Gösser Spritz">
                                <p class="text-white m-0 ml-4">Díszítsd eperszelettel, citrommal esetleg friss rozmaringgal vagy mentával!</p> 
                            </li>
                        </ul>
                        <a v-scroll-to="'#video'" class="text-white text-uppercase spritz-link d-block mt-5">Tovább a videóhoz <img class="img-fluid" src="@/assets/images/icons/arrow-down-white.svg" alt="Gösser Spritz"></a>
                    </div>
                </div>
            </div>
            <!-- 2 VIDEO -->
            <div class="row">
                <div class="col-12 col-lg-6 p-0 position-relative" id="videos">
                    <img class="img-fluid video-bg" src="@/assets/images/bgr/video-1-cover.jpg" alt="Gösser Spritz" @click="videoStart(1)" v-if="videoImg1">
                    <video width="100%" height="100%" controls id="videoOneShort" :class="videoImg1 ? 'd-none':'d-block'">
                        <source src="@/assets/images/video/Gosser_Spritz_6_Sec_V2.mp4" type="video/mp4"/>
                    </video>
                </div>
                <div class="col-12 col-lg-6 p-0 position-relative">
                    <img class="img-fluid video-bg" src="@/assets/images/bgr/video-2-cover.jpg" alt="Gösser Spritz" @click="videoStart(2)" v-if="videoImg2">
                    <video width="100%" height="100%" controls id="videoTwoShort" :class="videoImg2 ? 'd-none':'d-block'">
                        <source src="@/assets/images/video/Gosser_Spritz_6_Sec_V1.mp4" type="video/mp4"/>
                    </video>
                </div>
            </div>
            <!-- PLACES -->
            <div class="row">
                <div class="col-12 col-lg-6 p-0">
                    <img class="img-fluid" src="@/assets/images/products/spritz-places.jpg" alt="Gösser Spritz" style="object-fit: cover;width: 100%;height: 100%;">
                </div>
                <div class="col-12 col-lg-6 d-flex places justify-content-start flex-column" style="background:#006529;">
                    <h2 class="text-white">Jöhet egy sörkoktél?</h2>
                    <p class="text-white" style="font-size:18px;">Az alábbi helyszíneken te is belekortyolhatsz a teraszozás ízébe! Gyere el, és kóstold meg a Gösser Spritz sörkoktélokat "Strawberry" vagy "Botanic" változatban! Indulhat a teraszozás!</p>
                    <div class="d-flex flex-column mt-5" style="gap: 20px 0;overflow: auto;max-height:350px;" >
                        <div class="d-flex flex-row justify-content-start text-white pb-4" style="border-bottom: 1px solid #ffffff3b;">
                            <span>2024.június - július</span>
                            <span>Mintaszórás</span>
                            <span>Nehru-part</span>
                        </div>
                        <div class="d-flex flex-row justify-content-start text-white pb-4" style="border-bottom: 1px solid #ffffff3b;">
                            <span>2024.június - július</span>
                            <span>Mintaszórás</span>
                            <span>Kopaszi-gát</span>
                        </div>
                        <div class="d-flex flex-row justify-content-start text-white pb-4" style="border-bottom: 1px solid #ffffff3b;">
                            <span>2024.július 5-6.</span>
                            <span>Mintaszórás</span>
                            <span>Zamárdi, CBA Spórolós</span>
                        </div>
                        <div class="d-flex flex-row justify-content-start text-white pb-4" style="border-bottom: 1px solid #ffffff3b;">
                            <span>2024.július 12-13.</span>
                            <span>Mintaszórás</span>
                            <span>Bánkitó fesztivál - Bánk, Petényi út 6, 2653</span>
                        </div>
                        <div class="d-flex flex-row justify-content-start text-white pb-4" style="border-bottom: 1px solid #ffffff3b;">
                            <span>2024.július 19.</span>
                            <span>Mintaszórás</span>
                            <span>Balatonalmádi - Wesselényi strand</span>
                        </div>
                        <div class="d-flex flex-row justify-content-start text-white pb-4" style="border-bottom: 1px solid #ffffff3b;">
                            <span>2024.július 20.</span>
                            <span>Mintaszórás</span>
                            <span>Balatonakarattya - Bercsényi strand</span>
                        </div>
                        <div class="d-flex flex-row justify-content-start text-white pb-4" style="border-bottom: 1px solid #ffffff3b;">
                            <span>2024.július 21.</span>
                            <span>Mintaszórás</span>
                            <span>Alsóörs - Községi strand</span>
                        </div>
                        <div class="d-flex flex-row justify-content-start text-white pb-4" style="border-bottom: 1px solid #ffffff3b;">
                            <span>2024.augusztus 9.</span>
                            <span>Mintaszórás</span>
                            <span>Gyenesdiás - Játékstrand</span>
                        </div>
                        <div class="d-flex flex-row justify-content-start text-white pb-4" style="border-bottom: 1px solid #ffffff3b;">
                            <span>2024.augusztus 10.</span>
                            <span>Mintaszórás</span>
                            <span>Alsóörs - Községi strand</span>
                        </div>
                        <div class="d-flex flex-row justify-content-start text-white pb-4" style="border-bottom: 1px solid #ffffff3b;">
                            <span>2024.augusztus 11.</span>
                            <span>Mintaszórás</span>
                            <span>Balatonakarattya - Bercsényi strand</span>
                        </div>
                        <div class="d-flex flex-row justify-content-start text-white pb-4" style="border-bottom: 1px solid #ffffff3b;">
                            <span>2024.augusztus 16.</span>
                            <span>Mintaszórás</span>
                            <span>Balatonfűzfő - Föveny strand</span>
                        </div>
                        <div class="d-flex flex-row justify-content-start text-white pb-4" style="border-bottom: 1px solid #ffffff3b;">
                            <span>2024.augusztus 17.</span>
                            <span>Mintaszórás</span>
                            <span>Gyenesdiás - Játékstrand</span>
                        </div>
                        <div class="d-flex flex-row justify-content-start text-white pb-4" style="border-bottom: 1px solid #ffffff3b;">
                            <span>2024.augusztus 18.</span>
                            <span>Mintaszórás</span>
                            <span>Balatonalmádi - Wesselényi strand</span>
                        </div>
                        <div class="d-flex flex-row justify-content-start text-white pb-4" style="border-bottom: 1px solid #ffffff3b;">
                            <span>2024.július - augusztus</span>
                            <span>Mintaszórás</span>
                            <span>Balaton, Bahart hajók</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- VIDEO -->
        <div id="video">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/6gBv5G4sn-M?si=xfsvdJKveS2JksQv" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        <div class="container-fluid spritz">
            
            <!-- 3 ROWS -->
            <div class="row three-row-section">
                <div class="col-12 col-lg-4 p-0 position-relative">
                    <router-link :to="{ name: 'SpritzArticle1'}" class="text-center w-100 link">
                        <div class="cover-inner d-flex justify-content-center align-items-end">
                            <p class="text-white mb-5">Teraszozás a <span style="display:inline-block">Gösser Spritz</span><br>sörkoktélokkal</p>
                            <img class="img-fluid ml-md-4 mb-5 mr-3 mr-lg-3" src="@/assets/images/icons/circle-arrow-white.svg" alt="Gösser Spritz">
                        </div>
                    </router-link>
                </div>
                <div class="col-12 col-lg-4 hashtag-bg">
                    <a href="https://www.facebook.com/GosserHU" target="_blank" class="d-flex h-100 justify-content-center align-items-center flex-column">
                        <!--<img class="img-fluid" src="@/assets/images/bgr/spritz-row2.jpg" alt="Gösser Spritz" style="object-fit: cover;width:100%;height:100%;">-->
                        <p class="text-center hashtag mt-5">#GösserSpritz<br>#sorkoktel<br>#IndulhatATeraszozas<br>#gutbessergösser</p>
                        <p class="text-white hashtag-link mt-5 mb-0">Kövess minket</p>
                    </a>
                </div>
                <div class="col-12 col-lg-4 p-0 position-relative">
                    <router-link :to="{ name: 'SpritzArticle2'}" class="text-center w-100 link">
                        <div class="cover-inner cover-inner-row3 d-flex justify-content-center align-items-end">
                            <p class="text-white mb-5">Dobd fel a<br>teraszod!</p>
                            <img class="img-fluid ml-md-4 mb-5 mr-3 mr-lg-3" src="@/assets/images/icons/circle-arrow-white.svg" alt="Gösser Spritz">
                        </div>
                    </router-link>
                </div>
            </div>
            <!-- FORM -->
           <div class="row form">
                <div class="col-12 col-lg-6 offset-lg-3 p-0 text-center">
                    <h2>Ízlett a termék?</h2>
                    <p class="text mb-5">Szívesen vesszük visszajelzésed.</p>
                    <div class="form__padding-container" v-if="!formSubmitted">
                        <form @submit.prevent="formSubmit">
                            <div class="form-group text-left">
                                <label for="name">Név *</label>
                                <input v-model="name" type="text" class="form-control" id="name" placeholder="Kovács Lajos">
                            </div>
                            <div class="form-group text-left">
                                <label for="email">E-mail cím *</label>
                                <input v-model="email" type="text" class="form-control" id="email" placeholder="kovacs.lajos@gmail.com">
                            </div>
                            <div class="form-group text-left">
                                <label for="message">Üzenet *</label>
                                <textarea v-model="message" class="form-control" id="message" rows="3"></textarea>
                            </div>
                            <div class="form-check form-check-inline text-left">
                                <input class="form-check-input" type="checkbox" id="check" v-model="check">
                                <label class="form-check-label" for="check">Hozzájárulok, hogy megadott adataimat a Kapcsolatfelvétel során az adatkezelő kezelje. Bővebb információ az <a href="https://www.heineken.com/hu/hu/adatvedelmi-tajekoztato" target="_blank" style="color:#006529;font-weight:700;">Adatkezelési tájékoztatóban.</a> *</label>
                            </div>
                            <p class="text-left" style="font-size: 15px;color: #d31010;" v-if="error">Kérlek fogadd el a nyilatkozatot!</p>
                            <p class="text-left" style="font-size: 15px;color: #d31010;" v-if="serverError">Helytelenül kitöltött űrlap! Kérlek nézd meg minden adatot megadtál-e.</p>
                            <p class="text-left pb-5 pt-3">* kötelezően kitöltendő mező</p>
                            <button type="submit" class="btn btn-send">Üzenet küldése</button>
                            <div style="border-bottom: 2px solid rgb(0 101 41 / 21%);margin:40px 0;"></div>
                        </form>
                        <p class="social">Csatlakozz a teraszozók közösségéhez és mutasd meg a te <span>#gösserpillanat</span>-od!<br><span>#GösserSpritz</span></p>
                        <div class="d-flex justify-content-center align-items-center mt-4" style="gap:30px;">
                            <a href="https://www.facebook.com/GosserHU" target="_blank"><img class="img-fluid" src="@/assets/images/icons/spritz-facebook.svg" alt="Gösser Spritz"></a>
                            <a href="https://www.instagram.com/gosser_hu/" target="_blank"><img class="img-fluid" src="@/assets/images/icons/spritz-insta.svg" alt="Gösser Spritz"></a>
                        </div>
                    </div>
                    <div class="form__padding-container form-sended" v-else>
                        <p>Az üzenetet sikeresen elküldted, köszönjük!</p>
                        <button class="btn btn-send mt-3" @click="clearField">Új üzenet küldése</button>
                    </div>
                </div>
            </div>
            
            <Contact />
        </div>
    </div>
</template>

<script>
import Contact  from '@/components/contact.vue'
import axios from "axios";

export default {   
    data() {
        return {
            name: '',
            email: '',
            message: '',
            check: false,
            formSubmitted: false,
            error: false,
            serverError: false,
            videoImg1: true,
            videoImg2: true
        }
    },
    components:{
        Contact           
    },
    methods: {
        videoStart(id){
            if(id == 1){
                document.getElementById('videoOneShort').play();
                this.videoImg1 = false;
            }else{
                document.getElementById('videoTwoShort').play();
                this.videoImg2 = false;
            }
            
        },
        formSubmit(){
            let _this = this;

            this.error = false;
            this.serverError = false;
            
            if(this.check){
            
                axios.post('https://gosser.hu/beerspritz_api/contact.php', {
                    name: _this.name,
                    email: _this.email,
                    message: _this.message
                })
                .then(function (response) {
                    console.log(response);
                    _this.formSubmitted = true;
                   // _this.clearField();
                })
                .catch(function (error) {
                    if(!error.response.data.status){
                        _this.serverError = true;
                    }
                });
            }else{
                this.error = true;
            }

        },
        clearField(){
            this.name = '';
            this.email = '';
            this.message = '';
            this.check = false;
            this.formSubmitted = false;
        }
    }
}
</script>


