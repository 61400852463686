<template>
  <div class="premium" ref="premiumBlock" >
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-lg-6 premium--left-side" >
            <div class="premium--left-side--inside">
              
              <div class="premium--left-side--inside--split-img" v-inview="'fade-up'">
                <div class="premium--left-side--inside--split-img--in-small">
                    <!-- gösser premium -- mobil -->
                    <div id="gosserCarouselMobile" class="carousel slide" data-ride="carousel">
                      <div class="carousel-inner">
                        <div class="carousel-item active">
                          <img class="d-block w-100" src="@/assets/images/premium/carousel-premium-mob.png" alt="Gösser Premium 5%">
                        </div>
                        <div class="carousel-item">
                          <img class="d-block w-100" src="@/assets/images/premium/carousel-premium-2-mob.png" alt="Gösser Premium 5%">
                        </div>  
                        <div class="carousel-item">
                          <img class="d-block w-100" src="@/assets/images/premium/premium-mini.png" alt="Gösser Premium 5%">
                        </div>                             
                      </div>
                      <ol class="carousel-indicators">
                        <li data-target="#gosserCarouselMobile" data-slide-to="0" class="active"></li>
                        <li data-target="#gosserCarouselMobile" data-slide-to="1"></li>
                        <li data-target="#gosserCarouselMobile" data-slide-to="2"></li>                         
                      </ol>                    
                    </div>
                    <div class="premium-product-title--mobile">Gösser Premium 5%</div>
                </div>
              </div>

              <div class="premium--left-side--inside__content leftBlock-premium-5" ref="premium-5" v-inview="'fade-up'">
                  <h2 id="premium">Gösser Premium 5%</h2>
                  <p>
                    Merülj el a pillanatban a Gösser Premiummal!
                    <br> <br>
                    A válogatott természetes alapanyagokra építő, tradicionális Gösser Premium egy kiváló minőségű, 5% alkoholtartalmú világos sör. Saaz komló és 100% árpamaláta felhasználásával készül, így könnyed, 
                    malátás ízvilágot képvisel. Kiváló alapanyagok, állandó magas minőség – ez a Gösser Premium, ami tökéletes választás, ha a pörgős mindennapokban lassítanál kicsit.
                  </p>

                  <div class="wrapper">
                    <table>
                      <thead>
                        <tr>
                          <th colspan="3"><span>Gösser Premium 5%</span></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr class="bg-tr-light-gold">
                          <td></td>
                          <td>Energia- és tápérték <br> 100 ml termékben</td>
                          <td>Irányadó Napi Beviteli Érték <br>
                            %*</td>
                        </tr>
                        <tr>
                          <td class="first-cell-bg">Energia</td>
                         <td>175 kJ / 42 kcal</td>
                          <td>2,09 %</td>
                        </tr>
                        <tr>
                          <td class="first-cell-bg">Zsír</td>
                          <td>0,0 g</td>
                          <td>0,0 %</td>
                        </tr>
                        <tr>
                          <td class="first-cell-bg">Ebből telített</td>
                          <td>0,0 g</td>
                          <td>0,0 %</td>
                        </tr>
                        <tr>
                          <td class="first-cell-bg">Szénhidrát</td>
                          <td>3,4 g</td>
                          <td>1,31 %</td>
                        </tr>
                        <tr>
                          <td class="first-cell-bg">Ebből cukor</td>
                          <td>0,0 g</td>
                          <td>0 %</td>
                        </tr>
                              <tr>
                          <td class="first-cell-bg">Fehérje</td>
                          <td>0,3 g</td>
                          <td>0,6 %</td>
                        </tr>
                        <tr>
                          <td class="first-cell-bg">Só</td>
                          <td>0,0 g</td>
                          <td>0,0 %</td>
                        </tr>

                        <tr>
                          <th colspan="3" class="bottom-table-txt">* Referencia beviteli érték egy átlagos felnőtt számára (8400 kJ / 2000 kcal)</th>
                        </tr>
                        <tr>
                          <th colspan="3" class="bottom-table-txt">Összetevők: víz, árpamaláta, komló, komlókivonat</th>
                        </tr>
                      </tbody>
                    </table>  
                  </div>
                  <div class="premium--left-side--inside--icon-grup-gold">
                    <div class="premium--left-side--inside--icon-grup-gold--content">
                      <img src="@/assets/images/premium/palack-icon-gold.svg" alt="0,33 l palack">
                      <p>0,33 l palack</p>
                    </div>
                    <div class="premium--left-side--inside--icon-grup-gold--content">
                      <img src="@/assets/images/premium/palack-icon-gold.svg" alt="0,5 l palack">
                      <p>0,5 l palack</p>
                    </div>
                  </div>
              
                  <div class="premium--left-side--inside--icon-grup-gold">
                    <div class="premium--left-side--inside--icon-grup-gold--content">
                      <img src="@/assets/images/premium/doboz-icon-gold.svg" alt="0,5 l doboz">
                      <p>0,5 l doboz</p>
                    </div>
                    <div class="premium--left-side--inside--icon-grup-gold--content">
                      <img src="@/assets/images/premium/doboz-icon-gold.svg" alt="0,33 l doboz">
                      <p>0,33 l doboz</p>
                    </div>
                  </div>

                  <div class="premium--left-side--inside--icon-grup-gold">
                    <div class="premium--left-side--inside--icon-grup-gold--content">
                      <img src="@/assets/images/premium/multi-doboz-icon-gold.svg" alt="0,5 l dobozok">
                      <p>6 x 0,5 l doboz multipack</p>
                    </div>
                    <div class="premium--left-side--inside--icon-grup-gold--content">
                      <img src="@/assets/images/premium/pohar-icon-gold.svg" alt="csapolva">
                      <p>csapolva</p>
                    </div>
                  </div>

                  <div class="premium--left-side--inside--alcohol">
                    <p>Alkoholtartalom: 5%</p>
                  </div>
              </div>
               <!-- NATUR ZITRONE -2% -->
              <div class="premium--left-side--inside--split-img" v-inview="'fade-up'">
                <img class="divider-img" src="@/assets/images/premium/divider.svg" alt="split img"> 

                <div class="divider-mob--container">
                  <div class="divider-mob"></div>
                </div>

                <div class="premium--left-side--inside--split-img">                  
                  <div class="premium--left-side--inside--split-img--in-small">

                      <!-- natur zitrone --mobile -->
                        <div class="img-wrap">
                              <img class="d-block w-100" src="@/assets/images/premium/nature_zitrone_2_new-mob.png" alt="Gösser Natur Zitrone 2%">
                        </div>


                        <div class="premium-product-title--mobile">Gösser Natur Zitrone 2%</div>
                        
                  </div>
                </div>
              </div>
              
              <div class="premium--left-side--inside__content leftBlock-natur-zitrone-2" ref="natur-zitrone-2" v-inview="'fade-up'">
                  <h2 id="zitrone">Gösser Natur Zitrone 2%</h2>
                  <p>
                  Citrusos frissesség 100%-ban természetes alapanyagokból! 
                  <br> <br>
                 Válaszd az üdítően finom Gösser Natur Zitronét 2%-os alkoholtartalommal, mesterséges adalékanyag, ízesítő és színezék nélkül.<br><br>Frissítő ízzel kikapcsolódni szabad!
                   </p>
                                            
                  <div class="wrapper">
                    <table>
                      <thead>
                        <tr>
                          <th colspan="3"><span>Gösser Natur Zitrone 2%</span></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr class="bg-tr-light-gold">
                          <td></td>
                          <td>Energia- és tápérték <br> 100 ml termékben</td>
                          <td>Irányadó Napi Beviteli Érték <br>
                            %*</td>
                        </tr>
                        <tr>
                          <td class="first-cell-bg">Energia</td>
                          <td>151,7 kJ / 35,9 kcal</td>
                          <td>1,80 %</td>
                        </tr>
                        <tr>
                          <td class="first-cell-bg">Zsír</td>
                          <td>0,0 g</td>
                          <td>0,0 %</td>
                        </tr>
                              <tr>
                          <td class="first-cell-bg">Ebből telített</td>
                          <td>0,0 g</td>
                          <td>0,0 %</td>
                        </tr>
                              <tr>
                          <td class="first-cell-bg">Szénhidrát</td>
                          <td>5,9 g</td>
                          <td>2,27 %</td>
                        </tr>
                        <tr>
                          <td class="first-cell-bg">Ebből cukor</td>
                          <td>4,9 g</td>
                          <td>5,44 %</td>
                        </tr>
                              <tr>
                          <td class="first-cell-bg">Fehérje</td>
                          <td>0,2 g</td>
                          <td>0,4 %</td>
                        </tr>
                              <tr>
                          <td class="first-cell-bg">Só</td>
                          <td>0,0 g</td>
                          <td>0,0 %</td>
                        </tr>

                        <tr>
                          <th colspan="3" class="bottom-table-txt">* Referencia beviteli érték egy átlagos felnőtt számára (8400 kJ / 2000 kcal)</th>
                        </tr>
                        <tr>
                          <th colspan="3" class="bottom-table-txt">Összetevők: víz, árpamaláta, fruktóz, citrom-, narancslé sűrítményből, citromsav, komló komlókivonat, stabilizáló szerek: gumiarábikum, glicerin-észterek természetes gyantából, szentjánoskenyérmag-liszt, természetes aroma, antioxidáns: aszkorbinsav, citromfű kivonat
                          </th>
                        </tr>
                      </tbody>
                    </table>  
                  </div>

              
                  <div class="premium--left-side--inside--icon-grup-gold">
                    <div class="premium--left-side--inside--icon-grup-gold--content">
                      <img src="@/assets/images/premium/doboz-icon-gold.svg" alt="0,5 l doboz">
                      <p>0,5 l doboz</p>
                    </div>
                  </div>

                  <div class="premium--left-side--inside--alcohol">
                    <p>Alkoholtartalom: 2%</p>
                  </div>
              </div>
            <!-- NATUR ZITRONE 0%-->
              <div class="premium--left-side--inside--split-img" v-inview="'fade-up'">
                <img class="divider-img" src="@/assets/images/premium/divider.svg" alt="split img">

                <div class="divider-mob--container">
                  <div class="divider-mob"></div>
                </div>

                <div class="premium--left-side--inside--split-img--in-small">

                    <!-- natur zitrone --mobile -->
                      <div class="img-wrap">
                            <img class="d-block w-100" src="@/assets/images/premium/nature_citrone_newer-mob.png" alt="Gösser Natur Zitrone 2%">
                      </div>
                      <div class="premium-product-title--mobile">Gösser Natur Zitrone 0,0%</div>

                </div>
              </div>

              <div class="premium--left-side--inside__content leftBlock-natur-zitrone-0" id="zitrone0" ref="natur-zitrone-0" v-inview="'fade-up'">
                  <h2>Gösser Natur Zitrone 0,0%</h2>
                  <p>
                     Alkohol nélkül lazítani szabad!
                        <br> <br>
                        A Gösser Natur Zitrone 0,0% tökéletes alkoholmentes gyümölcsös szomjoltó. Remek választás, ha természetes felüdülésre vágysz.
                        <br> <br>        
                        A jól ismert Citrom, Mangó-citrom és Áfonya-citrom változatok mellett a különleges Málna-rebarbara és Görögdinnye-lime ízvilágok azokat a nyári pillanatokat idézik meg, amelyekben annyira szeretünk elmerülni. 
                        <br> <br>
                      
                        Bármelyik változat mellett is döntesz, a Gösser Natur Zitrone termékcsalád minden tagja 100%-ban természetes alapanyagokból készül, valódi értéket képviselve.
                  </p>

                                 

                    <div id="natur-zitrone-0-nutrition-tables" class="carousel slide carousel-fade" data-ride="carousel" data-interval="0">
                      <div class="carousel-inner">
                        <div class="carousel-item active">
                          <div class="wrapper">  
                                <!-- natur zitrone 0%  -->
                                <table>
                                  <thead>
                                    <tr>
                                      <th colspan="3"><span>Gösser Natur Zitrone 0,0% Citrom</span></th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr class="bg-tr-light-gold">
                                      <td></td>
                                      <td>Energia- és tápérték <br> 100 ml termékben</td>
                                      <td>Irányadó Napi Beviteli Érték <br>
                              %*</td>
                                    </tr>
                                    <tr>
                                      <td class="first-cell-bg">Energia</td>
                                      <td>137 kJ / 32 kcal</td>
                                      <td>1,6 %</td>
                                    </tr>
                                    <tr>
                                      <td class="first-cell-bg">Zsír</td>
                                      <td>0,0 g</td>
                                      <td>0,0 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Ebből telített</td>
                                      <td>0,0 g</td>
                                      <td>0,0 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Szénhidrát</td>
                                      <td>7,8 g</td>
                                      <td>2,9 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Ebből cukor</td>
                                      <td>7,0 g</td>
                                      <td>7,8 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Fehérje</td>
                                      <td>0,1 g</td>
                                      <td>0,2 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Só</td>
                                      <td>0,0 g</td>
                                      <td>0,0 %</td>
                                    </tr>

                                    <tr>
                                      <th colspan="3" class="bottom-table-txt">* Referencia beviteli érték egy átlagos felnőtt számára (8400 kJ / 2000 kcal)</th>
                                    </tr>
                                    <tr>
                                      <th colspan="3" class="bottom-table-txt">Összetevők: víz, árpamaláta, fruktóz, citrom-, narancslé sűrítményből, citromsav, komlókivonat, stabilizáló szerek: gumiarábikum, glicerin-észterek természetes gyantából, szentjánoskenyérmag-liszt, természetes aroma, Antioxidáns: Aszkorbinsav zsírsav-észterei, Tokoferolban gazdag kivonat, citromfű kivonat, szén-dioxid</th>
                                    </tr>
                                  </tbody>
                                </table> 
                          </div>
                        </div>
                        <div class="carousel-item">
                            <!-- natur zitrone 0%  -->
                            <div class="wrapper">  
                                <table>
                                  <thead>
                                    <tr>
                                      <th colspan="3"><span>Gösser Natur Zitrone 0,0% Áfonya-citrom</span></th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr class="bg-tr-light-gold">
                                      <td></td>
                                      <td>Energia- és tápérték <br> 100 ml termékben</td>
                                      <td>Irányadó Napi Beviteli Érték <br>
                                      %*</td>
                                    </tr>
                                    <tr>
                                      <td class="first-cell-bg">Energia</td>
                                      <td>124,2 kJ / 29,2 kcal</td>
                                      <td>1,46 %</td>
                                    </tr>
                                    <tr>
                                      <td class="first-cell-bg">Zsír</td>
                                      <td>0,0 g</td>
                                      <td>0,0 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Ebből telített</td>
                                      <td>0,0 g</td>
                                      <td>0,0 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Szénhidrát</td>
                                      <td>6,8 g</td>
                                      <td>2,62 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Ebből cukor</td>
                                      <td>6 g</td>
                                      <td>6,67 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Fehérje</td>
                                      <td>0,2 g</td>
                                      <td>0,4 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Só</td>
                                      <td>0,0 g</td>
                                      <td>0,0 %</td>
                                    </tr>

                                    <tr>
                                      <th colspan="3" class="bottom-table-txt">* Referencia beviteli érték egy átlagos felnőtt számára (8400 kJ / 2000 kcal)</th>
                                    </tr>
                                    <tr>
                                      <th colspan="3" class="bottom-table-txt">Összetevők: víz, árpamaláta, fruktóz, komlókivonat, limelé sűrítményből, citromlé sűrítményből, karamell maláta kivonat, vörös áfonyalé sűrítményből, kék áfonyalé sűrítményből, málnalé sűrítményből és egyéb természetes aromák, stabilizátor: szentjánoskenyérmag-liszt, szén-dioxid</th>
                                    </tr>
                                  </tbody>
                                </table> 
                            </div>
                        </div>
                        <div class="carousel-item">
                           <!-- natur zitrone 0%  -->
                          <div class="wrapper">  
                                <table>
                                  <thead>
                                    <tr>
                                      <th colspan="3"><span>Gösser Natur Zitrone 0,0% Mangó-citrom</span></th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr class="bg-tr-light-gold">
                                      <td></td>
                                      <td>Energia- és tápérték <br> 100 ml termékben</td>
                                      <td>Irányadó Napi Beviteli Érték <br> %*
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="first-cell-bg">Energia</td>
                                      <td>92 kJ / 22 kcal</td>
                                      <td>1,1 %</td>
                                    </tr>
                                    <tr>
                                      <td class="first-cell-bg">Zsír</td>
                                      <td>0,0 g</td>
                                      <td>0,0 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Ebből telített</td>
                                      <td>0,0 g</td>
                                      <td>0,0 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Szénhidrát</td>
                                      <td>5,2 g</td>
                                      <td>1,9 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Ebből cukor</td>
                                      <td>4,6 g</td>
                                      <td>5,1 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Fehérje</td>
                                      <td>0,1 g</td>
                                      <td>0,1 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Só</td>
                                      <td>0,0 g</td>
                                      <td>0,0 %</td>
                                    </tr>

                                    <tr>
                                      <th colspan="3" class="bottom-table-txt">* Referencia beviteli érték egy átlagos felnőtt számára (8400 kJ / 2000 kcal)</th>
                                    </tr>
                                    <tr>
                                      <th colspan="3" class="bottom-table-txt">Összetevők: víz, árpamaláta, fruktóz, komlókivonat, citromlé sűrítményből, citrom kivonat sűrítményből, mangólé sűrítményből, természetes édesítőszer: szteviol-glikozidok, antioxidáns: aszkorbinsav, stabilizátor: szentjánoskenyérmag-liszt, színezék: karotinok, szén-dioxid</th>
                                    </tr>
                                  </tbody>
                                </table>
                          </div>
                        </div>


<!-- //////////////////////////////////////////////////////////////////// -->

                        <div class="carousel-item">
                           <!-- natur Dinnye-lime 0%  -->
                          <div class="wrapper">  
                                <table>
                                  <thead>
                                    <tr>
                                      <th colspan="3"><span>Gösser Natur Zitrone 0,0% Görögdinnye-lime</span></th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr class="bg-tr-light-gold">
                                      <td></td>
                                      <td>Energia- és tápérték <br> 100 ml termékben</td>
                                      <td>Irányadó Napi Beviteli Érték <br> %*
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="first-cell-bg">Energia</td>
                                      <td>107 kJ / 25 kcal</td>
                                      <td>1.3%</td>
                                    </tr>
                                    <tr>
                                      <td class="first-cell-bg">Zsír</td>
                                      <td>0,0 g</td>
                                      <td>0,0 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Ebből telített</td>
                                      <td>0,0 g</td>
                                      <td>0,0 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Szénhidrát</td>
                                      <td>6,7 g</td>
                                      <td>2,5 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Ebből cukor</td>
                                      <td>6,0 g</td>
                                      <td>6,7 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Fehérje</td>
                                      <td>0.1 g</td>
                                      <td>0.2 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Só</td>
                                      <td>0,0 g</td>
                                      <td>0,0 %</td>
                                    </tr>

                                    <tr>
                                      <th colspan="3" class="bottom-table-txt">* Referencia beviteli érték egy átlagos felnőtt számára (8400 kJ / 2000 kcal)</th>
                                    </tr>
                                    <tr>
                                      <th colspan="3" class="bottom-table-txt">
                                        Összetevők: víz, árpamaláta, fruktóz, komlókivonat, citromhéjkivonat, citromlé sűrítményből, természetes aroma, limelé sűrítményből, színezék: feketerépalé sűrítményből, görögdinnyelé sűrítményből, szén-dioxid
                                        <br><br>
                                        A fátyolosságot, valamint az esetleges lerakódást az italban található gyümölcslé okozza!
                                      </th>
                                    </tr>
                                  </tbody>
                                </table>
                          </div>
                        </div>

                        <div class="carousel-item">
                           <!-- natur Malna-rebarbara 0%  -->
                          <div class="wrapper">  
                                <table>
                                  <thead>
                                    <tr>
                                      <th colspan="3"><span>Gösser Natur Zitrone 0,0% Málna-rebarbara</span></th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr class="bg-tr-light-gold">
                                      <td></td>
                                      <td>Energia- és tápérték <br> 100 ml termékben</td>
                                      <td>Irányadó Napi Beviteli Érték <br> %*
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="first-cell-bg">Energia</td>
                                      <td>105 kJ / 25 kcal</td>
                                      <td>1,3 %</td>
                                    </tr>
                                    <tr>
                                      <td class="first-cell-bg">Zsír</td>
                                      <td>0,0 g</td>
                                      <td>0,0 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Ebből telített</td>
                                      <td>0,0 g</td>
                                      <td>0,0 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Szénhidrát</td>
                                      <td>6,0 g</td>
                                      <td>2,2 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Ebből cukor</td>
                                      <td>4,9 g</td>
                                      <td>5,4 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Fehérje</td>
                                      <td>0,1 g</td>
                                      <td>0,1 %</td>
                                    </tr>
                                          <tr>
                                      <td class="first-cell-bg">Só</td>
                                      <td>0,0 g</td>
                                      <td>0,0 %</td>
                                    </tr>

                                    <tr>
                                      <th colspan="3" class="bottom-table-txt">* Referencia beviteli érték egy átlagos felnőtt számára (8400 kJ / 2000 kcal)</th>
                                    </tr>
                                    <tr>
                                      <th colspan="3" class="bottom-table-txt">
                                        Összetevők: víz, árpamaláta, fruktóz, komlókivonat, citromlé sűrítményből, narancslé sűrítményből, almalé sűrítményből, színezék: feketerépalé sűrítményből, természetes aroma, citromkivonat, rebarbaralé sűrítményből, málnalé sűrítményből, stabilizátor: szentjánoskenyérmag- liszt, szén-dioxid
                                        <br><br>
                                        A fátyolosságot, valamint az esetleges lerakódást az italban található gyümölcslé okozza!
                                      </th>
                                    </tr>
                                  </tbody>
                                </table>
                          </div>
                        </div>



                      </div>
                      <a class="carousel-control-prev" href="#natur-zitrone-0-nutrition-tables" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                      </a>
                      <a class="carousel-control-next" href="#natur-zitrone-0-nutrition-tables" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                      </a>
                      <ol class="carousel-indicators">
                        <li data-target="#natur-zitrone-0-nutrition-tables" data-slide-to="0" class="active"></li>
                        <li data-target="#natur-zitrone-0-nutrition-tables" data-slide-to="1"></li>
                        <li data-target="#natur-zitrone-0-nutrition-tables" data-slide-to="2"></li>
                      </ol>
                    </div>
                  

                  <div class="premium--left-side--inside--icon-grup-wrap-white">

                    <div class="premium--left-side--inside--icon-grup-wrap-white-content">
                      <div class="premium--left-side--inside--icon-grup-wrap-white-content-in">
                        <img src="@/assets/images/premium/citrom-icon-white.svg" alt="">
                        <p>Citrom</p>
                      </div>
                      <div class="premium--left-side--inside--icon-grup-wrap-white-content-in">
                        <img src="@/assets/images/premium/less-fruit-icon-white.svg" alt="Mangó-citrom">
                        <p>Mangó-citrom</p>
                      </div>
                    </div>

                    <div class="premium--left-side--inside--icon-grup-wrap-white-content">
                           <div class="premium--left-side--inside--icon-grup-wrap-white-content-in">
                        <img src="@/assets/images/premium/more-fruit-icon-white.svg" alt="Áfonya-citrom">
                        <p>Áfonya-citrom</p>
                      </div>

                      <div class="premium--left-side--inside--icon-grup-wrap-white-content-in">
                        <img src="@/assets/images/premium/dinnye-icon-white.svg" alt="Mangó-citrom">
                        <p>Görögdinnye-lime</p>
                      </div>
                    </div>

                    <div class="premium--left-side--inside--icon-grup-wrap-white-content">
                      <div class="premium--left-side--inside--icon-grup-wrap-white-content-in">
                        <img src="@/assets/images/premium/malna-icon-white.svg" alt="Áfonya-citrom">
                        <p>Málna-rebarbara</p>
                      </div>
                    </div>
                  </div>

                  <div class="premium--left-side--inside--icon-grup-gold">
                    <div class="premium--left-side--inside--icon-grup-gold--content">
                      <img src="@/assets/images/premium/palack-icon-gold.svg" alt="0,33 l palack">
                      <p>0,33 l palack*</p>
                    </div>
                    <div class="premium--left-side--inside--icon-grup-gold--content">
                      <img src="@/assets/images/premium/doboz-icon-gold.svg" alt="0,5 l doboz">
                      <p>0,5 l doboz</p>
                    </div>
              
                  </div>

                    <div class="premium--left-side--inside--text">
                      <p>* Csak Gösser Nature Zitrone 0,0% Citrom.</p>
                    </div>
                  
                  <div class="premium--left-side--inside--alcohol more-mrgn">
                    <p>Alkoholtartalom: 0,0%</p>
                  </div>
              </div>
            </div>
        </div>

        <!-- ASZTALI -->
        <div class="col-12 col-lg-6 premium--right-side" >
          <div class="premium--right-side--inside" id="premium-right-side">          

            <!-- Gösser accordion -->
            <div class="premium--right-side--inside--details">
              
                <div class="premium--right-side--inside--details--accordion">
                  <div id="accordion">
                    <!-- premium 5% -->
                    <div class="card" id="rightBlock-premium-5">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                          <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" v-scroll-to="'#premium'">
                            Gösser Premium 5%
                          </button>
                        </h5>
                      </div>
                      <div id="collapseOne" class="collapse show" data-parent="#accordion">
                        <div class="card-body">
                          <!-- carousel -->
                          <div id="gosserCarousel" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                              <div class="carousel-item active">
                                <img class="d-block sticky-img" src="@/assets/images/premium/carousel-premium.jpg" alt="Gösser Premium 5%">
                              </div>
                              <div class="carousel-item">
                                <img class="d-block sticky-img" src="@/assets/images/premium/carousel-premium-2.jpg" alt="Gösser Premium 5%">
                              </div>   
                              <div class="carousel-item">
                                <img class="d-block sticky-img" src="@/assets/images/premium/premium-mini.jpg" alt="Gösser Premium 5%">
                              </div>                           
                            </div>
                            <a class="carousel-control-prev" href="#gosserCarousel" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#gosserCarousel" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                          
                        </div>
                      </div>
                    </div>

                    <!-- gösser Natur zitrone -->
                    <div class="card" id="rightBlock-natur-zitrone-2">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                          <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseOne" v-scroll-to="'#zitrone'">
                            Gösser Natur Zitrone 2%
                          </button>
                        </h5>
                      </div>
                      <div id="collapseTwo" class="collapse" data-parent="#accordion">
                        <div class="card-body">
                          <div class="img-wrap">
                            <img class="d-block sticky-img" src="@/assets/images/premium/nature_zitrone_2_new.jpg" alt="Gösser Natur Zitrone 2%">
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- natur zitrone 0 -->
                    <div class="card" id="rightBlock-natur-zitrone-0">
                      <div class="card-header" id="headingTwo">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseTwo" v-scroll-to="'#zitrone0'">
                            Gösser Natur Zitrone 0,0%
                          </button>
                        </h5>
                      </div>
                      <div id="collapseThree" class="collapse" data-parent="#accordion">
                        <div class="card-body">
                          <div class="img-wrap">
                            <img class="d-block sticky-img" src="@/assets/images/premium/nature_citrone_newer.jpg" alt="Gösser Natur Zitrone 0,0%">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
    
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    makeSticky(el, myClass){

      this.setImgHeight()

      var rightItem = document.getElementById('premium-right-side')
      var relTopPos = el.clientHeight - window.innerHeight  
      
      console.log('reltoppos', el.clientHeight, window.innerHeight)

      function isOnTop(item, classname){
        let rect = item.getBoundingClientRect();              
        let windowHeight = window.innerHeight
        let itemHeight = item.clientHeight                     

        if ( rect.top < 80 && windowHeight < (itemHeight - (-1*rect.top)) ) {    
          //the item is in viewport                 
          item.classList.add(classname)   
          item.classList.remove('bottomScroll')
          rightItem.removeAttribute('style')
        } else if (windowHeight > (itemHeight - (-1*rect.top)) && rect.top < 80 ){
          // the item is in viewport but we are at the end
          item.classList.add('bottomScroll')                      
          rightItem.style.transform =  ("translate(0," + relTopPos + "px")        
        } else {
          //item is not in the viewport
          item.classList.remove(classname) 
          item.classList.remove('bottomScroll')   
          rightItem.removeAttribute('style')   
        }
      }

      function initAnimation(){        
        if (document.readyState === 'complete') {                                     
            isOnTop(el, myClass)
            clearInterval(animPageLoad)
        }  
      }
                    
      var animPageLoad = setInterval(() => {
        initAnimation()
      }, 300);           
      
      window.addEventListener('scroll', function(){
        isOnTop(el, myClass)
      })
    },
    showCorrectBlock(item){
      // let _this = this
      let itemTop = item.getBoundingClientRect().top

      if (itemTop < 80 && itemTop > 0){  
        let collapseItems = document.getElementsByClassName('collapse')
        let collapseBtns = document.querySelectorAll("button[data-toggle='collapse']")
        
        collapseItems.forEach(el => {
          el.classList.remove('show')          
        })
        collapseBtns.forEach(element =>{
          element.setAttribute("aria-expanded", "false")
        })

         if ( item.className.indexOf('leftBlock-premium-5') > -1){
          // console.log('leftBlock-premium-5')                      
          document.getElementById('collapseOne').classList.add('show')
          document.querySelectorAll("button[data-target='#collapseOne']")[0].setAttribute("aria-expanded", "true")
         } else if (item.className.indexOf('leftBlock-natur-zitrone-2') > -1){
          //  console.log('leftBlock-natur-zitrone-2')           
           document.getElementById('collapseTwo').classList.add('show')
           document.querySelectorAll("button[data-target='#collapseTwo']")[0].setAttribute("aria-expanded", "true")
         }else  if (item.className.indexOf('leftBlock-natur-zitrone-0') > -1){
          //  console.log('leftBlock-natur-zitrone-0')           
           document.getElementById('collapseThree').classList.add('show')
           document.querySelectorAll("button[data-target='#collapseThree']")[0].setAttribute("aria-expanded", "true")
         }
      }

    },
    setGtm(btnText){
      this.GTtrackGA4({
            'event' : 'event',
            'category': 'termek',
            'action': 'vasarlas_gombra_kattintas',
            'button': 'vasarlas',
            'product': btnText,
            'action_type':'gomb_kattintas'
        })
    },
    setImgHeight(){
      // set the image height inside the rigth sticky block - we should see the whole content on any desctop height
      let windowHeight = window.innerHeight
      let accordionBtnHeight = document.getElementsByClassName('card-header')[0].clientHeight
      let rightMainBlock = document.getElementById('premium-right-side')
      let rightBlockTopMargin = window.getComputedStyle(rightMainBlock, null).getPropertyValue('margin-top').slice(0, -2)
      // let btnHeight = document.getElementById('premium-right-side').getElementsByClassName('btn--primary')[0].clientHeight

      // console.log(btnHeight)

      let stikcyImages = document.getElementsByClassName('sticky-img')      
      //console.log('stikcyImages', stikcyImages)
      // let imgHeight = windowHeight - (accordionBtnHeight * 3) - parseInt(rightBlockTopMargin) -btnHeight - 100
      let imgHeight = windowHeight - (accordionBtnHeight * 3) - parseInt(rightBlockTopMargin) - 100

      if (Array.isArray(stikcyImages)){
        stikcyImages.forEach(item => {
          item.setAttribute('style', 'height:' + imgHeight + 'px')
        })
      }
      

    }
  },
  mounted(){
      let _this = this

      //make the right section sticky
      this.makeSticky(_this.$refs.premiumBlock, 'fixedTop')

      window.addEventListener('resize', function(){
        _this.makeSticky(_this.$refs.premiumBlock, 'fixedTop')
      })
      
      //show the correct content in the right block
      window.addEventListener('scroll', function(){
          var leftContentBlock = document.getElementsByClassName('premium--left-side--inside__content')

          if (Array.isArray(leftContentBlock)){
            leftContentBlock.forEach(element => {
              _this.showCorrectBlock(element)
            });
          }

      })

  }
}
</script>


